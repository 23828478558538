import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from '../models/modeles';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  errorMessage;
  loading: boolean;

  constructor(
    private toastr: ToastrService,
    private user_service:UserService,
    private router:Router,
    private auth_service:AuthenticationService
  ) { }

  getCurrentUserByToken(token:string):User{
      var user:User = null;
      //var token = localStorage.getItem('token');
      if(token == undefined || token == null || token == ""){

        this.auth_service.loginWToken(token).subscribe({
          next:data =>{
            if(data != null){
              //this.currentUser = data
              //this.user_service.updateCurrentUser(data);
              user = data;
            }else{
              this.router.navigateByUrl('/sessions/signin');
            }
            
          },
          error:error =>{
              this.errorMessage = error.message;
              console.error("There is an error !", error)
              setTimeout(() => {
                this.loading = false;
                this.toastr.error('There is an error.', 'Error!', {progressBar: true});
              }, 3000);
          }
        })
      }
      return user;
  }
}
