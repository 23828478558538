import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Reception, Traitement } from '../models/modeles';

@Injectable({
  providedIn: 'root'
})
export class ReceptionService {

  insert_reception_url = environment.apidomain+"reception/add-reception";
  get_All_reception_url = environment.apidomain+"reception/get-all-reception";
  get_All_reception_by_company_url = environment.apidomain+"reception/get-company-reception";
  update_traitement_url = environment.apidomain+"traitement/update-traitement";
  get_traitement_by_state_url = environment.apidomain+"traitement/get-company-traitement";

  constructor(private http: HttpClient) { }

  insertReception<Reception>(reception: Reception){
    return this.http.post(this.insert_reception_url, reception)
  }

  updateTraitement<Traitement>(traitement: Traitement){
    return this.http.post(this.update_traitement_url, traitement)
  }

  getAllReception(idcompany):Observable<Reception[]>{
    return this.http.get<Reception[]>(this.get_All_reception_by_company_url,{
      params:{
        "idcompany":idcompany
      }
    })
  }

  getAllTraitementByStatus(idcompany,status):Observable<any>{
    return this.http.get(this.get_traitement_by_state_url,{
      params:{
        "idcompany":idcompany,
        "status":status
      }
    })
  }

}
