import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  insert_registraion_url = environment.apidomain+"event/add-registration";
  get_All_registraion_url = environment.apidomain+"event/get-all-registration";
  get_registraion_by_id_url = environment.apidomain+"event/get-registration-id";
  //graph_url = environment.apidomain+"traitement/do-trait/"
  graph_url = "http://localhost:8000/traitement/do-trait/"
  //graph_url = "http://clinicalloadbalancer-1729596340.us-east-1.elb.amazonaws.com:8000/traitement/do-trait/"

  email_url = "http://localhost:8000/api/send-email2";

  constructor(private http:HttpClient) { }

  insertRegistration<Registration>(registration: Registration){
    return this.http.post(this.insert_registraion_url, registration)
  }

  generateGraph(data:any){
    return this.http.post(this.graph_url, data).pipe(
      retry(10), // retry 10 times when it fails
    )
  }

  sendEmail(data:any){
    return this.http.post(this.email_url, {
      id: data
    });
    /* .pipe(
      retry(10), // retry 10 times when it fails
    ) */
  }

  getAllRegistration():Observable<any>{
    return this.http.get(this.get_All_registraion_url)
  }

  getById(id:any){
    return this.http.get(this.get_registraion_by_id_url,{
      params:{
        id:id
      }
    });
  }
}
