import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { User } from '../models/modeles';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user:User = new User()
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private dataSource: BehaviorSubject<User> = new BehaviorSubject<User>(this.user);
  currentUser:Observable<User> = this.dataSource.asObservable();
  loading: boolean;
  errorMessage;

  constructor() { }

   /**
     * Setter & getter for user
     *
     * @param value
     */
   set user_(value: User)
   {
       // Store the value
       this._user.next(value);
   }

   get user$(): Observable<User>
   {
       return this._user.asObservable();
   }

  /* update de Admin */
  updateCurrentUser(user: User){
    this.dataSource.next(user);
  }
}
