import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recucaisse',
  templateUrl: './recucaisse.component.html',
  styleUrls: ['./recucaisse.component.scss']
})
export class RecucaisseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
