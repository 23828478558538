import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DisplayLoadingService } from '../Services/display-loading.service';

@Injectable()
export class LoadspinnerInterceptor implements HttpInterceptor {

  private count = 0;

  constructor(
    private loadingservice : DisplayLoadingService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(this.count === 0){
      this.loadingservice.show();
    }

    this.count ++ ;
    return next.handle(request).pipe(
      finalize(()=>{
        this.count -- ;
        if(this.count == 0){
          this.loadingservice.hide();
        }
      })
    );
  }
}
