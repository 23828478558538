<div class="container mt-6">
    <h1 class="text-center text-bold text-danger"><strong>Registration form</strong></h1>
    <h3 class="text-center text-primary">Event Africa for ever feb 2023</h3>
    <div class="row">
        <div class="col-md-12">
            <form action="" #f="ngForm" (ngSubmit)="f.form.valid && submit(f)">

                <div ngModelGroup="event" #event="ngModelGroup">
                    <div class="col-md-12 form-group mb-3">
                        <input type="hidden" class="form-control" name="event_title" id="event_title" value="Africa Event" ngModel #event_title="ngModel">
                        <input type="hidden" class="form-control" name="event_place" id="event_place" value="Michigan" ngModel #event_place="ngModel" >
                        <input type="hidden" class="form-control" name="event_date" id="event_date" value="2023-02-13" ngModel #event_date="ngModel">
                    </div>
                </div>
                <div ngModelGroup="person" #person="ngModelGroup">
                    <div class="col-md-12 form-group mb-3">
                        <label class="text-lg text-bold" for="lastName">First name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" name="firstname" id="firstname" placeholder="type the first name" ngModel #firstname="ngModel" required="">
                    </div>
                    <div class="col-md-12 form-group mb-3">
                        <label class="text-lg text-bold" for="lastName">Middle name<span class="text-danger"></span></label>
                        <input type="text" class="form-control" name="middlename" id="middlename" placeholder="type the middle name if any" ngModel #middlename="ngModel">
                    </div>
                    <div class="col-md-12 form-group mb-3">
                        <label for="lastName">Last name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" name="lastname" id="lastname" placeholder="type the last name" ngModel #lastName="ngModel" required="">
                    </div>
                    <div class="col-md-12 form-group mb-3">
                        <label for="lastName">Email<span class="text-danger">*</span></label>
                        <input type="email" class="form-control" id="email" name="email" placeholder="type the email" ngModel #email="ngModel" required="">
                    </div>
                    <div class="row col-md-12 form-group mb-3">
                        <div class="col-md-3">
                            <label for="lastName">Country code<span class="text-danger">*</span></label>
                            <select class="form-control" name="country_code" ngModel #country_code="ngModel" required="">
                                <ng-container *ngFor="let country of countryArray">
                                    <option value="{{country.dial_code}}">{{country.name}}  {{country.dial_code}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-md-9">
                            <label for="lastName">Telephone<span class="text-danger">*</span></label>
                            <input type="tel" class="form-control" id="phone" name="phone" placeholder="type the phone number" ngModel #phone="ngModel" required="">                        
                        </div>
                    </div>
                </div>
                <!-- <hr class="text-warning"> -->
                <div class="col-md-12 form-group mb-3">
                    <label class="">Type of registration<span class="text-danger">*</span></label>
                    <div class="col-md-11" *ngFor="let regtype of array_regtype">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="RegistrationType" id="RegistrationType" ngModel #regtype="ngModel"
                                value="{{ regtype | json }}" checked required="">
                            <label class="form-check-label" for="regtype">
                               {{ regtype.type_name}}
                            </label>
                        </div>
                    </div>
                </div>
                <div ngModelGroup="company" #company="ngModelGroup">
                    <div class="col-md-12 form-group mb-3">
                        <label class="" for="company">Company<span class="text-danger pl-3">(if type of registration is company, type the name of the company)</span></label>
                        <input type="text" class="form-control" name="company_name" id="company_name" placeholder="type the name of the company" ngModel #company_name="ngModel">
                    </div>
                </div>
                <div class="col-md-12 form-group mb-3" ngModelGroup="company" #company="ngModelGroup">
                    <label for="lastName">Company industry<span class="text-danger"></span></label>
                    <select class="form-control" name="industry" id="" ngModel #industry="ngModel">
                        <ng-container *ngFor="let indus of array_indus">
                            <option value="{{indus | json}}">{{indus.industry_name}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-md-12 form-group mb-3" ngModelGroup="company" #company="ngModelGroup">
                    <label class="" for="industry_other">Other<span class="text-danger pl-3"></span></label>
                    <input type="text" class="form-control" name="industry_other" id="industry_other" placeholder="type the name of the industry" ngModel #industry_other="ngModel">
                </div>
                <div class="col-md-12 form-group mb-3" >
                    <label for="lastName">Quality of the guess<span class="text-danger">*</span></label>
                    <select class="form-control" name="quality" id="" ngModel #quality="ngModel" required="">
                        <ng-container *ngFor="let quality of array_quality">
                            <option value="{{quality | json}}">{{quality.quality_name}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-md-12 form-group mb-3 text-right">
                    <btn-loading btnClass="btn-warning" style="width: 500px;" [loading]="loading">Submit the form</btn-loading>
                </div>
            </form>
        </div>
    </div>
</div>

