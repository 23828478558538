import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplayLoadingService {

  isDisplay = new  BehaviorSubject<boolean>(false);

  constructor() { }

  show(){
    this.isDisplay.next(true);
  }

  hide(){
    this.isDisplay.next(false);
  }

}
