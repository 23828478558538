<div class="row h-25" style="background-image: url('/assets/images/checking.jpg'); background-repeat: no-repeat; background-size: cover;">

</div>
<div class="container h-100 mt-5">
    <div class="container" *ngIf="reg_detail">
        <div class="row justify-content-center">
            <div class="row">
                <h1 class="h1">Event</h1> 
            </div>
        </div>
        <div class="">
            <div class="h4 w-50" style="color: goldenrod;">
                <u>First name:</u> 
            </div>
            <div class="h2">
                {{reg_detail.person.firstname}}
            </div>
        </div>
        <div class="" *ngIf="reg_detail.person.middlename">
            <div class="h4 w-50" style="color: goldenrod;">
                <u>Middle name:</u> 
            </div>
            <div class="h2">
                {{reg_detail.person.middlename}}
            </div>
        </div>
        <div class="">
            <div class="h4 w-50" style="color: goldenrod;">
                <u>Last name:</u> 
            </div>
            <div class="h2">
                {{reg_detail.person.lastname}}
            </div>
        </div>
        <div class="">
            <div class="h4 w-50" style="color: goldenrod;">
                <u>Email:</u> 
            </div>
            <div class="h2">
                {{reg_detail.person.email}}
            </div>
        </div>
        <div class="">
            <div class="h4 w-50" style="color: goldenrod;">
                <u>Phone:</u> 
            </div>
            <div class="h2">
                {{reg_detail.person.phone}}
            </div>
        </div>
        <div class="" *ngIf="reg_detail.person.company">
            <div class="h4 w-50" style="color: goldenrod;">
                <u>Company:</u> 
            </div>
            <div class="h2" *ngIf="reg_detail.person.company">
                {{reg_detail.person.company.company_name}}
            </div>
        </div>
        <div class="">
            <div class="h4 w-50" style="color: goldenrod;">
                <u>Guest quality:</u> 
            </div>
            <div class="h2">
                {{reg_detail.quality.quality_name}}
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="d-flex justify-content-center pr-2">
                <button class="btn btn-primary btn-lg">confirm</button>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-danger btn-lg">Reject</button>
            </div>
        </div>
    </div>
</div>

