import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'src/app/Services/registration.service';

@Component({
  selector: 'app-display-registration',
  templateUrl: './display-registration.component.html',
  styleUrls: ['./display-registration.component.scss']
})
export class DisplayRegistrationComponent implements OnInit {

  idregustration="";
  reg_detail:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private regist_service : RegistrationService
  ) { }

  ngOnInit(): void {
    this.idregustration = this.route.snapshot.paramMap.get('id');
    this.getRegistrationById(this.idregustration);
  }

  getRegistrationById(id){
    this.regist_service.getById(id).subscribe({
      next:data=>{
        console.log(JSON.stringify(data));
        this.reg_detail = data;
      },
      error:error=>{
        console.log(error)
      }
    })
  }

}
