import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject, Subject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const CHAT_URL = "ws://localhost:8080/gs-guide-websocket";

export interface Message {
    from: string;
    text: string;
    data:string
}
  // Declare SockJS and Stomp
declare var SockJS;
declare var Stomp;
  
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {


  constructor() {
    this.initializeWebSocketConnection();
  }
  public stompClient;
  public msg = [];
  initializeWebSocketConnection() {
    const serverUrl = environment.websocketserver+'gs-guide-websocket';
    const ws = new SockJS(serverUrl);
    this.stompClient = Stomp.over(ws);
    const that = this;
    // tslint:disable-next-line:only-arrow-functions
    this.stompClient.connect({}, function(frame) {
      that.stompClient.subscribe('/topic/greetings', (message) => {
        if (message.body) {
          that.msg.push(message.body);
          console.log(message.body)
        }
      });
    });
  }
  
  sendMessage(message) {
    this.stompClient.send('/app/hello' , {}, JSON.stringify(message));
  }
}
