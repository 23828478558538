import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Registration } from 'src/app/models/modeles';
import { RegistrationService } from 'src/app/Services/registration.service';
import Swal from 'sweetalert2';

var Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

@Component({
  selector: 'app-clinical-analysis',
  templateUrl: './clinical-analysis.component.html',
  styleUrls: ['./clinical-analysis.component.scss']
})
export class ClinicalAnalysisComponent implements OnInit {

  loading:boolean;
  errorMessage;
  graph_url = null;
  interpretation="";

  constructor(
    private graph:RegistrationService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  submit(f:NgForm){

      this.loading = true;

      console.log(JSON.stringify(f.value))
      this.graph.generateGraph(f.value).subscribe({
        next:data =>{
          console.log(JSON.stringify(data['graph_url']));
          
          setTimeout(() => {
            this.loading = false;
            this.graph_url = "http://clinicalloadbalancer-1729596340.us-east-1.elb.amazonaws.com:8000"+data['graph_url'];
            ///this.graph_url = "http://localhost:8000"+data['graph_url'];
            this.interpretation = data['interpretation'];
            Toast.fire({
              icon: 'success',
              title: 'data updated successfully'
            })
            /* .then(()=>{
              f.reset();
              Swal.fire({
                title: 'Success!',
                text: 'Thank you for successfully register to the Trade Summit. You will be receiving an email confirmation shortly and the instruction how how to proceed the day of the event registrering',
                icon: 'success',
                confirmButtonText: 'OK'
              });
            }); */
  
          }, 2000);
        },
        error:error =>{
          this.errorMessage = error.message;
          console.error("There is an error !", error)
          setTimeout(() => {
            this.loading = false;
            this.toastr.error('There is an error.', 'Error!', {progressBar: true});
          }, 3000);
        }
      })
  }
}
