import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Company, Event, Industry, QualityGuess, Registration, RegistrationType } from 'src/app/models/modeles';
import { EventParamService } from 'src/app/Services/event-param.service';
import { RegistrationService } from 'src/app/Services/registration.service';
import Swal from 'sweetalert2';
import { getList } from 'country-list-with-dial-code-and-flag';


var Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

  loading:boolean;
  array_indus:Industry[]=[];
  array_quality:QualityGuess[]=[];
  array_regtype:RegistrationType[]=[];
  errorMessage;
  registration:Registration;
  countryArray

  constructor(
    private param_service:EventParamService,
    private toastr: ToastrService,
    private regist_service : RegistrationService
  ) { }

  ngOnInit(): void {
    this.getAllIndustry();
    this.getAllQuality();
    this.getAllRegType();  
    
    this.countryArray = getList();
    //console.log(JSON.stringify(this.countryArray));
  }

  submit(f:NgForm){

    this.registration = new Registration();

    var event = new Event();
    event.event_title = "Africa For Ever";
    event.event_date = "2023-02-10";
    event.place = "Michigan Illinois";

    //this.registration.event = f.value.event;
    this.registration.event = event;
    this.registration.regtype = JSON.parse(f.value.RegistrationType);

    this.registration.person = f.value.person;

    //add the county code to the number
    var country_code = f.value.person.country_code;
    f.value.person.phone = "("+country_code+")"+f.value.person.phone;

    //console.log(JSON.stringify(f.value.person));

    var industry:Industry = f.value.company.industry ? JSON.parse(f.value.company.industry):null;
    
    var company:Company = new Company();
    company.company_name = f.value.company.company_name;
    company.industry_other = f.value.company.industry_other;
    company.industry = industry;

    this.registration.person.company = company.company_name==""? null : company;
    this.registration.quality = JSON.parse(f.value.quality);
    
    //console.log(JSON.stringify(this.registration));
    //console.log(JSON.stringify(company));
    
    //this.toastr.success('data updated.', 'Success!', {progressBar: true});

    if(f.valid){

      this.loading = true;

      this.regist_service.insertRegistration(this.registration).subscribe({
        next:data =>{
          console.log(data['person']['id']);
          setTimeout(() => {
            this.loading = false;
            Toast.fire({
              icon: 'success',
              title: 'data updated successfully'
            })
            .then(()=>{
              f.reset();
              Swal.fire({
                title: 'Success!',
                text: 'Thank you for successfully register to the Trade Summit. You will be receiving an email confirmation shortly and the instruction how how to proceed the day of the event registrering',
                icon: 'success',
                confirmButtonText: 'OK'
              }).then(
                  m => {
                  return this.sendEmail(data['person']['id']);
                }
              );
            });
  
          }, 1000);
        },
        error:error =>{
          this.errorMessage = error.message;
          console.error("There is an error !", error)
          setTimeout(() => {
            this.loading = false;
            this.toastr.error('There is an error.', 'Error!', {progressBar: true});
          }, 3000);
        }
      })
    }

  }

  getAllIndustry(){
    this.param_service.getAllIndustry().subscribe(
      {
        next:data =>{
          this.array_indus = data;
        },
        error:error =>{
          this.errorMessage = error.message;
          console.error("There is an error !", error)
          setTimeout(() => {
            this.loading = false;
            this.toastr.error('There is an error.', 'Error!', {progressBar: true});
          }, 3000);
        }
      }
    ) 
  }

  getAllQuality(){
    this.param_service.getAllQualityGuess().subscribe(
      {
        next:data =>{
          this.array_quality = data;
        },
        error:error =>{
          this.errorMessage = error.message;
          console.error("There is an error !", error)
          setTimeout(() => {
            this.loading = false;
            this.toastr.error('There is an error.', 'Error!', {progressBar: true});
          }, 3000);
        }
      }
    ) 
  }

  getAllRegType(){
    this.param_service.getAllRegistrationType().subscribe(
      {
        next:data =>{
          this.array_regtype = data;
        },
        error:error =>{
          this.errorMessage = error.message;
          console.error("There is an error !", error)
          setTimeout(() => {
            this.loading = false;
            this.toastr.error('There is an error.', 'Error!', {progressBar: true});
          }, 3000);
        }
      }
    ) 
  }

  sendEmail(id){
    this.regist_service.sendEmail(id).subscribe(
      {
        next:data=>{
          console.log(data);
        },
        error:error=>{
          console.log(error);
        }
      }
    )
  }

}
