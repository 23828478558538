import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/modeles';
import { UserService } from 'src/app/Services/user.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean ;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };

    currentUser:User = JSON.parse(decodeURIComponent(atob(localStorage.getItem("currentUser"))));

    

    selectedItem: IMenuItem;
    
    userRole;
    
    constructor(
        private user_service:UserService
    ) {
        //console.log(this.currentUser);
    }



    defaultMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { icon: 'i-Clock-3', name: 'Version 1', state: '/admin/dashboard/v1', type: 'link' },
                { icon: 'i-Clock-4', name: 'Version 2', state: '/admin/dashboard/v2', type: 'link' },
                { icon: 'i-Over-Time', name: 'Version 3', state: '/admin/dashboard/v3', type: 'link' },
                { icon: 'i-Clock', name: 'Version 4', state: '/admin/dashboard/v4', type: 'link' },
            ]
        },
        {
            name: 'UI kits',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-Bell', name: 'Alerts', state: '/admin/uikits/alerts', type: 'link' },
                { icon: 'i-Split-Horizontal-2-Window', name: 'Accordions', state: '/admin/uikits/accordions', type: 'link' },
                { icon: 'i-Medal-2', name: 'Badges', state: '/admin/uikits/badges', type: 'link' },
                {
                    icon: 'i-Arrow-Right-in-Circle',
                    name: 'Buttons',
                    type: 'dropDown',
                    sub: [
                        { name: 'Bootstrap Buttons', state: '/admin/uikits/buttons', type: 'link' },
                        { name: 'Loding Buttons', state: '/admin/uikits/buttons-loading', type: 'link' }
                    ]
                },
                { icon: 'i-ID-Card', name: 'Cards', state: '/admin/uikits/cards', type: 'link' },
                { icon: 'i-Line-Chart-2', name: 'Cards metrics', state: '/admin/uikits/cards-metrics', type: 'link' },
                { icon: 'i-Credit-Card', name: 'Cards widget', state: '/admin/uikits/cards-widget', type: 'link' },
                { icon: 'i-Full-Cart', name: 'Cards ecommerce', state: '/admin/uikits/cards-ecommerce', type: 'link' },
                { icon: 'i-Duplicate-Window', name: 'Modals', state: '/admin/uikits/modals', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Popover', state: '/admin/uikits/popover', type: 'link' },
                { icon: 'i-Like', name: 'Rating', state: '/admin/uikits/rating', type: 'link' },
                { icon: 'i-Loading-3', name: 'Loaders', state: '/admin/uikits/loaders', type: 'link' },
            ]
        },
        {
            name: 'Apps',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Computer-Secure',
            sub: [
                { icon: 'i-Add-File', name: 'Invoice Builder', state: '/admin/invoice', type: 'link' },
                { icon: 'i-Email', name: 'Inbox', state: '/admin/inbox', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Chat', state: '/admin/chat', type: 'link' },
                { icon: 'i-Calendar', name: 'Calendar', state: '/admin/calendar', type: 'link' },
            ]
        },
        {
            name: 'Forms',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Clipboard-File--Text',
            sub: [
                { icon: 'i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
                { icon: 'i-Split-Vertical', name: 'Form layouts', state: '/admin/forms/layouts', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Input Group', state: '/admin/forms/input-group', type: 'link' },
                { icon: 'i-File-Edit', name: 'Input Mask', state: '/admin/forms/input-mask', type: 'link' },
                { icon: 'i-Tag-2', name: 'Tag Input', state: '/admin/forms/tag-input', type: 'link' },
                { icon: 'i-Width-Window', name: 'Wizard', state: '/admin/forms/wizard', type: 'link' },
                { icon: 'i-Crop-2', name: 'Image Cropper', state: '/admin/forms/img-cropper', type: 'link' },
            ]
        },
        {
            name: 'Data Tables',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Horizontal-Text',
            sub: [
                { icon: 'i-File-Horizontal-Text', name: 'List', state: '/admin/tables/list', type: 'link' },
                { icon: 'i-Full-View-Window', name: 'Fullscreen', state: '/admin/tables/full', type: 'link' },
                { icon: 'i-Code-Window', name: 'Paging', state: '/admin/tables/paging', type: 'link' },
                { icon: 'i-Filter-2', name: 'Filter', state: '/admin/tables/filter', type: 'link' },
            ]
        },
        {
            name: 'Sessions',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Administrator',
            sub: [
                { icon: 'i-Add-User', name: 'Sign up', state: '/sessions/signup', type: 'link' },
                { icon: 'i-Checked-User', name: 'Sign in', state: '/sessions/signin', type: 'link' },
                { icon: 'i-Find-User', name: 'Forgot', state: '/sessions/forgot', type: 'link' }
            ]
        },
        {
            name: 'Pages',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Windows-2',
            sub: [
                { icon: 'i-Male', name: 'User Profile', state: '/admin/pages/profile', type: 'link' }
            ]
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/admin/icons/iconsmind'
        },
        {
            name: 'Others',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Double-Tap',
            sub: [
                { icon: 'i-Error-404-Window', name: 'Not found', state: '/others/404', type: 'link' }
            ]
        },
        {
            name: 'Doc',
            type: 'extLink',
            tooltip: 'Documentation',
            icon: 'i-Safe-Box1',
            state: 'http://demos.ui-lib.com/gull-doc'
        }
    ];

    customMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Espace de visualisation de données',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/admin/dashboard/default',
            sub: [
                { 
                    icon: 'i-Clock-3', 
                    name: 'Version 1', 
                    state: '/admin/dashboard/default', 
                    type: 'link' ,
                    active:true
                },
                /* { 
                    icon: 'i-Clock-4', 
                    name: 'Version 2', 
                    state: '/admin/dashboard/v2', 
                    type: 'link' ,
                    active:true
                },
                { 
                    icon: 'i-Clock-4', 
                    name: 'Version 3', 
                    state: '/admin/dashboard/v3', 
                    type: 'link' ,
                    active:true
                },
                { 
                    icon: 'i-Clock', 
                    name: 'Version 4', 
                    state: '/admin/dashboard/v4', 
                    type: 'link' ,
                    active:true
                }, */
            ],
            
        },
        {
            name: 'Gestion des receptions',
            description: 'Espace dédié à la gestion des receptions',
            type: 'dropDown',
            icon: 'i-Ticket',
            sub: [
               /*  { 
                    icon: 'i-Filter-2', 
                    name: 'Liste des entrées', 
                    state: '/admin/reception/liste-entrees', 
                    type: 'link', 
                    active:true 
                }, */
                //{ icon: 'i-File-Clipboard-Text--Image', name: 'Enregistrer une nouvelle entrée', state: '/admin/reception/ajouter-entree', type: 'link' }
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer une nouvelle entrée', 
                    state: '/ajouter-entree', 
                    type: 'link',
                    active:true 
                },
                { 
                    icon: 'i-Align-Right', 
                    name: 'Afficher la file d\'attente', 
                    state: '/display-file-attente', 
                    type: 'link',
                    active:true 
                } 
                /* { 
                    icon: 'i-Align-Right', 
                    name: 'Afficher la file d\'attente', 
                    state: '/file-attente', 
                    type: 'link',
                    active:true 
                } */
                
            ]
        },
       
        {
            name: 'Gestion des Traitements',
            description: 'Espace de traitement des entrées',
            type: 'dropDown',
            icon: 'i-Files',
            sub: [
                { 
                    icon: 'i-File-Horizontal-Text', 
                    name: 'Tickets en attente de traitements', 
                    state: '/admin/traitement/liste-traitement', 
                    type: 'link', 
                    active:true 
                },
                { 
                    icon: 'i-Left', 
                    name: 'Tickets transferés', 
                    state: '/admin/traitement/liste-transferes', 
                    type: 'link', 
                    active:true 
                }
                
            ]
        },
        {
            name: 'Gestion des Caisses',
            description: 'Espace de gestion de entrées et sorties de caisse',
            type: 'dropDown',
            icon: 'i-Financial',
            sub: [
                { 
                    icon: 'i-Billing', 
                    name: 'Liste des operations de caisse', 
                    state: '/admin/caisse/liste-operation', 
                    type: 'link', 
                    active:true 
                }
                
            ]
        },
        {
            name: 'Gestion des Clients',
            description: 'Espace de gestion de clients',
            type: 'dropDown',
            icon: 'i-Car-Coins',
            sub: [
                { 
                    icon: 'i-Receipt-3', 
                    name: 'Liste des prospections', 
                    state: '/admin/clients/liste-prospections', 
                    type: 'link', 
                    active:true 
                },
                { 
                    icon: 'i-Full-Cart', 
                    name: 'Liste des clients', 
                    state: '/admin/clients/liste-clients', 
                    type: 'link', 
                    active:true 
                },
                
            ]
        },
        {
            name: 'Gestion des Projet',
            description: 'Espace de gestion de projets',
            type: 'dropDown',
            icon: 'i-Folders',
            sub: [
                { 
                    icon: 'i-Newspaper', 
                    name: 'Liste des projets', 
                    state: '/admin/projets/liste-projets', 
                    type: 'link', 
                    active:true 
                },
                
            ]
        },
        {
            name: 'Gestion des Produits',
            description: 'Espace de gestion de produits',
            type: 'dropDown',
            icon: 'i-Shop-2',
            sub: [
                { 
                    icon: 'i-Receipt-4', 
                    name: 'Liste des produits', 
                    state: '/admin/produits/liste-produits', 
                    type: 'link', 
                    active:true 
                }
            ]
        },
        {
            name: 'Gestion des agents',
            description: 'Espace dédié à la gestion des clients',
            type: 'dropDown',
            icon: 'i-Business-Mens',
            disabled:this.currentUser?.agent?.role?.role_name!=="Administrateur",
            sub: [
                { 
                    icon: 'i-Filter-2', 
                    name: 'Liste des agents', 
                    state: '/admin/agents/liste-agent', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouvel agent', 
                    state: '/admin/agents/ajouter-agent', 
                    type: 'link' ,
                    active:true 
                }
                
            ]
        },
        {
            name: 'Reservations management',
            description: '',
            type: 'dropDown',
            icon: 'i-Book',
            disabled:this.currentUser?.agent?.role?.role_name!=="Administrateur",
            sub: [
                { 
                    icon: 'i-Filter-2', 
                    name: 'reservations list', 
                    state: '/admin/manage-event/list-register', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-Edit', 
                    name: 'Edit reservations', 
                    state: '/admin/manage-event/register', 
                    type: 'link',
                    active:true  
                }
            ]
        },
        {
            name: 'Parametres Departements',
            description: 'Espace dédié à la gestion des clients',
            type: 'dropDown',
            icon: 'i-Door',
            disabled:this.currentUser?.agent?.role?.role_name!=="Administrateur",
            sub: [
                { 
                    icon: 'i-Management', 
                    name: 'Liste des departements', 
                    state: '/admin/parametres/liste-depart', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouveau departement', 
                    state: '/admin/parametres/ajouter-depart', 
                    type: 'link' ,
                    active:true 
                },   
                { 
                    icon: 'i-Management', 
                    name: 'Liste des services', 
                    state: '/admin/parametres/liste-service', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouveau service', 
                    state: '/admin/parametres/ajouter-service', 
                    type: 'link',
                    active:true  
                },      
                { 
                    icon: 'i-Management', 
                    name: 'Liste des operations', 
                    state: '/admin/parametres/liste-operation', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer une nouvel operation', 
                    state: '/admin/parametres/ajouter-operation', 
                    type: 'link',
                    active:true  
                },  
                { 
                    icon: 'i-Management', 
                    name: 'Liste des roles', 
                    state: '/admin/parametres/liste-role', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouveau role', 
                    state: '/admin/parametres/ajouter-role', 
                    type: 'link',
                    active:true  
                },    
                { 
                    icon: 'i-Management', 
                    name: 'Liste des guichets', 
                    state: '/admin/parametres/liste-guichet', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouveau guichet', 
                    state: '/admin/parametres/ajouter-guichet', 
                    type: 'link',
                    active:true  
                },      
            ]
        },
        {
            name: 'Analystique',
            description: 'Espace dédié aux statistiques',
            type: 'dropDown',
            icon: 'i-Statistic',
            disabled:this.currentUser?.agent?.role?.role_name!=="Administrateur",
            sub: [
                { 
                    icon: 'i-Management', 
                    name: 'Statistique des receptions', 
                    state: '/admin/reception/liste-entrees', 
                    type: 'link',
                    active:true 
                },         
                { 
                    icon: 'i-Bar-Chart-4', 
                    name: 'Statistique des agents', 
                    state: '/admin/analytics/agent-performance', 
                    type: 'link',
                    active:true 
                }         
            ]
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/admin/icons/iconsmind',
            disabled:false 
        },
        /*{
            name: 'Commandes',
            description: 'Espace dédié à la gestion des commandes',
            type: 'dropDown',
            icon: 'i-Receipt-3',
            sub: [
                { icon: 'i-Receipt-4', name: 'Liste commande', state: '/tables/paging', type: 'link' },
                { icon: 'i-Shopping-Cart', name: 'Enregistrer une commande', state: '/forms/wizard', type: 'link' },
                { icon: 'i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
            ]
        },
        {
            name: 'Cloud',
            description: 'Espace dédié à la gestion des commandes',
            type: 'dropDown',
            icon: 'i-Clouds',
            sub: [
                { icon: 'i-Share-on-Cloud', name: 'Liste commande', state: '/tables/paging', type: 'link' },
                //{ icon: 'i-Shopping-Cart', name: 'Enregistrer une commande', state: '/forms/wizard', type: 'link' },
                
            ]
        },
        {
            name: 'Cloud',
            description: 'Espace dédié à la gestion des documents',
            type: 'link',
            icon: 'i-Clouds',
            state: '/tables/paging'
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/icons/iconsmind'
        },
        {
            name: 'Apps',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Computer-Secure',
            sub: [
                { icon: 'i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
                { icon: 'i-Email', name: 'Inbox', state: '/inbox', type: 'link' },
                { icon: 'i-Speach-Bubble-3', name: 'Chat', state: '/chat', type: 'link' },
                { icon: 'i-Calendar', name: 'Calendar', state: '/calendar', type: 'link' },
            ]
        },
        {
            name: 'Forms',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-File-Clipboard-File--Text',
            sub: [
                { icon: 'i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
                { icon: 'i-Split-Vertical', name: 'Form layouts', state: '/forms/layouts', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Input Group', state: '/forms/input-group', type: 'link' },
                { icon: 'i-File-Edit', name: 'Input Mask', state: '/forms/input-mask', type: 'link' },
                { icon: 'i-Tag-2', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
                { icon: 'i-Width-Window', name: 'Wizard', state: '/forms/wizard', type: 'link' },
                { icon: 'i-Crop-2', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
            ]
        },*/
    ]

    MenuCaisseProsp: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Espace de visualisation de données',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/admin/dashboard/default',
            sub: [
                { 
                    icon: 'i-Clock-3', 
                    name: 'Version 1', 
                    state: '/admin/dashboard/v1', 
                    type: 'link' ,
                    active:true
                },
                { 
                    icon: 'i-Clock-4', 
                    name: 'Version 2', 
                    state: '/admin/dashboard/v2', 
                    type: 'link' ,
                    active:true
                }
            ],
            
        },
        {
            name: 'Gestion des Caisses',
            description: 'Espace de gestion de entrées et sorties de caisse',
            type: 'dropDown',
            icon: 'i-Financial',
            sub: [
                { 
                    icon: 'i-Billing', 
                    name: 'Liste des operations de caisse', 
                    state: '/admin/caisse/liste-operation', 
                    type: 'link', 
                    active:true 
                }
                
            ]
        },
        {
            name: 'Gestion des Clients',
            description: 'Espace de gestion de clients',
            type: 'dropDown',
            icon: 'i-Car-Coins',
            sub: [
                { 
                    icon: 'i-Receipt-3', 
                    name: 'Liste des prospections', 
                    state: '/admin/clients/liste-prospections', 
                    type: 'link', 
                    active:true 
                },
                
            ]
        },
        {
            name: 'Gestion des Produits',
            description: 'Espace de gestion de produits',
            type: 'dropDown',
            icon: 'i-Shop-2',
            disabled:this.currentUser?.agent?.role?.id!="1",
            sub: [
                { 
                    icon: 'i-Receipt-4', 
                    name: 'Liste des produits', 
                    state: '/admin/produits/liste-produits', 
                    type: 'link', 
                    active:true 
                }
            ]
        },
        {
            name: 'Gestion des agents',
            description: 'Espace dédié à la gestion des clients',
            type: 'dropDown',
            icon: 'i-Business-Mens',
            disabled:this.currentUser?.agent?.role?.id!="1",
            sub: [
                { 
                    icon: 'i-Filter-2', 
                    name: 'Liste des agents', 
                    state: '/admin/agents/liste-agent', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouvel agent', 
                    state: '/admin/agents/ajouter-agent', 
                    type: 'link' ,
                    active:true 
                }
                
            ]
        },
        {
            name: 'Parametres Departements',
            description: 'Espace dédié à la gestion des clients',
            type: 'dropDown',
            icon: 'i-Door',
            disabled:this.currentUser?.agent?.role?.id!="1",
            sub: [
                { 
                    icon: 'i-Management', 
                    name: 'Liste des departements', 
                    state: '/admin/parametres/liste-depart', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouveau departement', 
                    state: '/admin/parametres/ajouter-depart', 
                    type: 'link' ,
                    active:true 
                },   
                { 
                    icon: 'i-Management', 
                    name: 'Liste des services', 
                    state: '/admin/parametres/liste-service', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouveau service', 
                    state: '/admin/parametres/ajouter-service', 
                    type: 'link',
                    active:true  
                },      
                { 
                    icon: 'i-Management', 
                    name: 'Liste des operations', 
                    state: '/admin/parametres/liste-operation', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer une nouvel operation', 
                    state: '/admin/parametres/ajouter-operation', 
                    type: 'link',
                    active:true  
                },  
                { 
                    icon: 'i-Management', 
                    name: 'Liste des roles', 
                    state: '/admin/parametres/liste-role', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouveau role', 
                    state: '/admin/parametres/ajouter-role', 
                    type: 'link',
                    active:true  
                },    
                { 
                    icon: 'i-Management', 
                    name: 'Liste des guichets', 
                    state: '/admin/parametres/liste-guichet', 
                    type: 'link' ,
                    active:true 
                },
                { 
                    icon: 'i-File-Clipboard-Text--Image', 
                    name: 'Enregistrer un nouveau guichet', 
                    state: '/admin/parametres/ajouter-guichet', 
                    type: 'link',
                    active:true  
                },      
            ]
        },
        {
            name: 'Icons',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/admin/icons/iconsmind',
            disabled:false 
        },
    ]

    monMenu: IMenuItem[] = [
        {   
            name: 'Dashboard',
            description: 'Espace de visualisation de données',
            type: 'dropDown',
            icon: 'i-Bar-Chart',
            sub: [
                { 
                    icon: 'i-Clock-3', 
                    name: 'Version 1', 
                    state: '/admin/dashboard/v1', 
                    type: 'link',
                    active: true
                },
                { 
                    icon: 'i-Clock-4', 
                    name: 'Version 2', 
                    state: '/admin/dashboard/v2', 
                    type: 'link',
                    active: true
                },
                { 
                    icon: 'i-Clock', 
                    name: 'Version 4', 
                    state: '/admin/dashboard/v4', 
                    type: 'link',
                    active: true 
                },
            ],
            disabled:false
            
        }
    ]
    // sets iconMenu as default;

    menuItems = new BehaviorSubject<IMenuItem[]>(this.customMenu);

    // navigation component has subscribed to this Observable

    menuItems$ = this.menuItems.asObservable();

    userType = this.currentUser?.agent?.role?.role_name;
    publishNavigationChange(userType);

    // You can customize this method to supply different menu for
    // different user type.
    
    publishNavigationChange(userType: string): void {
        switch (userType) {
        case 'Administrateur':
           this.menuItems.next(this.monMenu);
        break;
        default:
           this.menuItems.next(this.MenuCaisseProsp);
       }
    }

    


}
