import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { User } from 'src/app/models/modeles';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  insert_reception_url = environment.apidomain+"user/add-user";
  envoie_sms_url = environment.pythonapidomain+"infobip/send-sms/";
  login_url = environment.apidomain+"user/login";
  login_token_url = environment.apidomain+"user/login-token";
  get_All_agent_url = environment.apidomain+"user/get-all-user";
  get_All_role_url = environment.apidomain+"user/get-all-role";
  get_All_role_by_company_url = environment.apidomain+"user/get-company-role";

  _authenticated:boolean;
  errorMessage;
  loading;

  constructor(
    private http:HttpClient,
    private user_service:UserService,
    private router: Router
  ) { }

  insertUser<User>(user: User){
    return this.http.post(this.insert_reception_url, user)
  }

  login<User>(user: User){
    return this.http.post(this.login_url, user).pipe(
      switchMap((response:any) => {

        if(response != null){
            // Store the access token in the local storage
            if(response.token != null){
              localStorage.setItem("token",response.token);
              //localStorage.setItem("currentUser",btoa(encodeURIComponent(response.agent)));
              //localStorage.setItem("currentUser",JSON.stringify(response));
              localStorage.setItem("currentUser",btoa(encodeURIComponent(JSON.stringify(response))));
              //localStorage.setItem("currentUser",JSON.stringify(response));
            }

            console.log("Le user "+JSON.stringify(response));

            // Set the authenticated flag to true
            this._authenticated = true;

            //save the current user
            this.user_service.updateCurrentUser(response);

            return of(response)
        }
        return of(response) 
      })
    )
  }

  loginWToken<User>(token:string){
    return this.http.post(this.login_token_url, token).pipe(
      switchMap((response:any) => {

        if(response != null){
            // Store the access token in the local storage
            if(response.token != null)localStorage.setItem("token",response.token)

            // Set the authenticated flag to true
            this._authenticated = true;

            //save the current user
            this.user_service.updateCurrentUser(response);

            return of(response)
        }
        return of(response) 
      })
    )
  }

  getAllUser():Observable<any>{
    return this.http.get(this.get_All_agent_url)
  }

  getAllRole(idcompany):Observable<any>{
    return this.http.get(this.get_All_role_by_company_url,{
      params:{
        'idcompany':idcompany
      }
    })
  }

  sendValidationText<User>(message: any){
    return this.http.post(this.envoie_sms_url, message)
  }

  getCurrentUser(){
    var token;
    return this.user_service.currentUser.subscribe(data=>{
      
      if(data.token == undefined){
        token = localStorage.getItem('token');
        this.loginWToken(token).subscribe({
          next:data =>{
            if(data != null){
              return data;
            }else{
              this.router.navigateByUrl('/admin/sessions/signin');
            }
          },
          error:error =>{
              this.errorMessage = error.message;
              console.error("There is an error !", error);
          }
        })
      }
      else{
        return data;
        //console.log("the connected user is "+JSON.stringify(data));
      }
    });
  }

}
