import { DepartementModule } from "../views/departement/departement.module";

export class Agent{
    id:string;
    nom:string;
    prenom:string;
    email:string;
    phone1:string;
    phone2:string;
    service:Service;
    role:Role;
    guichet:Guichet;
    company:Company;
    operations:Operation[];
    privileges:Privilege[];
}

export class Departement{
    id:string;
    libelle:string;
    company:Company;
}

export class Guichet{
    id:string;
    libelle:string;
    company:Company;
}

export class Reception{
    id:string;
    nom:string;
    prenom:string;
    email:string;
    phone1:string;
    phone2:string;
    ticket_numero:string;
    ticket_date_creation:string;
    ticket_heure_creation:string;
    service:Service;
    company:Company;
    operation:Operation;
    ticket_top_actif:string;
    typeRecep:TypeReception;
    agentVisite:Agent;
}

export class Traitement{
    id:string;
    reception:Reception;
    agent:Agent;
    recipiendaire:Agent;
    status:string;
    commentaire:string;
    ticket_date_update:string;
    ticket_heure_update:string;
    traitement_topActif:string;
    company:Company;
}

export class Role{
    id:string;
    role_name:string;
    privilege: Privilege[];
    role_topActif:boolean;
    company:Company;
}

export class Operation{
    id:string;
    libelle:string;
    estimation_temps:string;
    service:Service;
    role_topActif:boolean;
    company:Company;
}

export class Privilege{
    id:string;
    privilege_name:string;
    categorie:string;
    privalege_topActif:string;
}

export class Service{
    id:string;
    libelle:string;
    departement:Departement;
    company:Company
}

export class TypeReception{
    id:string;
    libelle:string;
    company:Company;
}

export class User{
    id:string;
    login:string;
    token:string;
    password:string;
    codeActivation:string;
    agent:Agent;
    company:Company;
}

export class Company{
    id:string;
    company_name:string;
    company_topActif:boolean;
    industry:Industry;
    industry_other:string;
}

export class Industry{
    id:string;
    industry_name:string;
    other_name:string;
    industry_topActif:boolean
}

export class QualityGuess{
    id:string;
    quality_name:string;
    quality_topActif:boolean
}

export class RegistrationType{
    id:string;
    type_name:string;
    type_topActif:string;
}

export class Event{
    id:string;
    event_title:string;
    place:string;
    event_date:string;
    type_topActif:string;
}

export class Person{
    id:string;
    firstname:string;
    lastname:string;
    middlename:string;
    phone:string;
    company:Company;
}

export class Registration {
    id:string;
    event:Event;
    person:Person;
    regtype:RegistrationType;
    quality:QualityGuess;
    registration_topActif:boolean
}