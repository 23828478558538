<!-- <div class="row" style="padding-top: 3%; margin-left: 30%;">
    
</div> -->
<div class="row container-fluid my-5">
    <div class="col-md-5">
        <img src="/assets/images/logo_qless_reduit.png" width="100px" alt="">
    </div>
    <div class="col-md-5">
        <div>Invoice 42</div>
        <div>Date: 04/12/2017</div>
    </div>
    <div class="row mx-auto col-11">
        Bonjour le Jour
    </div>
</div>