import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Reception, User } from 'src/app/models/modeles';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { ReceptionService } from 'src/app/Services/reception.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-file-attente',
  templateUrl: './file-attente.component.html',
  styleUrls: ['./file-attente.component.scss']
})
export class FileAttenteComponent implements OnInit {

  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  
  arrayReception: Reception[]=[];
  currentUser:User;
  errorMessage;
  loading: boolean;

  constructor(
    private receptionService:ReceptionService,

    private toastr: ToastrService,
    private user_service:UserService,
    private router:Router,
    private auth_service:AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser();
    
  }

  getAllReception(){
    this.receptionService.getAllReception(this.currentUser.agent.company.id).subscribe({
      next:data =>{
        this.arrayReception = data
        console.log("les agent sont:"+ JSON.stringify(this.arrayReception));
      },
      error:error =>{
        console.error("There is an error !", error)
      }
    })
  }

  select(row){
    console.log("the selected item is :"+JSON.stringify(row));
  }

  getCurrentUser(){
    var token;
    this.user_service.currentUser.subscribe(data=>{
      
      if(data.token == undefined){
        token = localStorage.getItem('token');
        this.auth_service.loginWToken(token).subscribe({
          next:data =>{
            if(data != null){
              this.currentUser = data
              this.getAllReception();
            }else{
              this.router.navigateByUrl('/sessions/signin');
            }
            
          },
          error:error =>{
              this.errorMessage = error.message;
              console.error("There is an error !", error)
              setTimeout(() => {
                this.loading = false;
                this.toastr.error('There is an error.', 'Error!', {progressBar: true});
              }, 3000);
          }
        })
      }
      else{
        this.currentUser = data;
        this.getAllReception();
        console.log("the connected user is "+JSON.stringify(data));
      }
    });
  }
}
