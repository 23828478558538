<div class="breadcrumb">
    <h1>Gestion des receptions</h1>
    <ul>
      <li><a href="#">Entrée</a></li>
      <li>Ajouter une nouvelle entrée</li>
    </ul>
</div>

<div class="separator-breadcrumb border-top"></div>

<div class="row">
    <div class="col-sm-12 col-lg-10">
        <div class="card mb-4">
            <div class="card-body">
                <div class="card-title mb-3">Formulaire des entrées</div>
                <form #f="ngForm" (ngSubmit)="f.form.valid && submit(f)">
                    <div class="row">
                        <div class="col-md-12 form-group mb-3">
                            <label for="firstName">Nom</label>
                            <input type="text" class="form-control" [(ngModel)]="reception.nom" id="nom" name="nom" required="" ngModel #nom="ngModel" placeholder="Entrer votre nom">
                        </div>

                        <div class="col-md-12 form-group mb-3">
                            <label for="lastName">Prenom</label>
                            <input type="text" class="form-control" id="prenom" [(ngModel)]="reception.prenom" name="nom" required="" ngModel #nom="ngModel" placeholder="Entrer votre prenom">
                        </div>

                        <div class="col-md-12 form-group mb-3">
                            <label for="phone">Numero 1</label>
                            <input class="form-control" id="phone1" name="phone1" required="" [(ngModel)]="reception.phone1" ngModel #phone1="ngModel" placeholder="Enter numero">
                        </div>

                        <div class="col-md-12 form-group mb-3">
                            <label for="phone">Numero 2</label>
                            <input class="form-control" id="phone2" name="phone2" [(ngModel)]="reception.phone2" ngModel #phone2="ngModel" placeholder="Enter numero">
                        </div>

                        <!-- <div class="col-md-6 form-group mb-3">
                            <label for="credit">Cradit card number</label>
                            <input class="form-control" id="credit" placeholder="Card">
                        </div>

                        <div class="col-md-6 form-group mb-3">
                            <label for="credit">Website</label>
                            <input class="form-control" id="credit" placeholder="Web address">
                        </div>

                        <div class="col-md-6 form-group mb-3">
                            <label for="picker1">Birth date</label>
                            <div class="input-group">
                                <input id="picker1" class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                    ngbDatepicker #dp="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary" (click)="dp.toggle()" type="button">
                                        <i class="icon-regular i-Calendar-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-md-12 form-group mb-3">
                            <label for="picker1">Selectionner le motif de reception</label>
                            <select class="form-control" (change)="selectTypeReception($event)" id="typeRecep" name="typeRecep" required [(ngModel)]="reception.typeRecep" ngModel #typeReception="ngModel" required>
                                <option></option>
                                <ng-container >
                                    <option *ngFor="let typeRecep of arrayTypeReception" value="{{typeRecep | json}}">{{typeRecep.libelle}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-md-12 form-group mb-3" *ngIf="isTypeRecepVisit && isAgentDisplay">
                            <label for="picker1">Selectionner le nom de la personne que vous souhaitez visiter</label>
                           
                                <input class="form-control" [formControl]="myControl" type="text" placeholder="Selectionnez le nom de l'agent que vous venez visiter"
                                    aria-label="Agent" matInput [matAutocomplete]="auto" id="agentVisite" name="agentVisite">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let agent of filteredOptions | async" [value]="agent.nom+' '+agent.prenom" (click)="selectAgent(agent)">
                                        {{agent.nom+' '+agent.prenom+" | "+agent.service.libelle}}
                                    </mat-option>
                                </mat-autocomplete>
                        </div>
                        <div class="col-md-12 form-group mb-3" *ngIf="!isTypeRecepVisit">
                            <label for="picker1">Selectionner le service</label>
                            <select class="form-control" (change)="getOperationByService($event)" id="service" name="service" required [(ngModel)]="reception.service" ngModel #nameservice="ngModel" required>
                                <option></option>
                                <ng-container >
                                    <option *ngFor="let service of arrayService" value="{{service.id}}">{{service.libelle}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-md-12 form-group mb-3" *ngIf="!isTypeRecepVisit">
                            <label for="picker1">Selectionner l'operation souhaitée</label>
                            <select class="form-control" id="operation" name="operation" required [(ngModel)]="reception.operation" ngModel #operation="ngModel" required>
                                
                                <ng-container *ngFor="let operation of arrayOperationDispalyed">
                                    <option value="{{operation | json}}">{{operation.libelle}}</option>
                                </ng-container>
                            </select>
                        </div>

                        <div class="col-md-12">
                            <btn-loading btnClass="btn-primary btn-rounded" [loading]="loading">Submit</btn-loading>
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>