<div class="container mt-6">
    <h1 class="text-center text-bold text-danger"><strong>Clinical Analysis</strong></h1>
    <h3 class="text-center text-primary">pH analysis and support</h3>
    <div class="row">
        <div class="col-md-12">
            <form action="" #f="ngForm" (ngSubmit)="f.form.valid && submit(f)">

                <div>
                    <div class="col-md-12 form-group mb-3 text-center">
                        <legend class="text-left"><strong>Graph</strong></legend>
                    </div>
                    <div class="col-md-12 form-group mb-3 text-center">
                        <img *ngIf="graph_url != null" [src]="graph_url" width="850" height="500">
                    </div>
                    <div class="col-md-12 form-group mb-3 text-center">
                        <legend class="text-left"><strong>Interpretation</strong></legend>
                        <textarea style="width: 100%;" rows="10" [value]="interpretation" [style.fontSize]="20"></textarea>
                    </div>
                    <div class="col-md-12 form-group mb-3 text-right">
                        <button>Save the result</button>
                    </div>
                </div>
                <div>
                    <div class="col-md-12 form-group mb-3 text-center">
                        <legend class="text-left"><strong>Forms</strong></legend>
                    </div>
                    <div class="col-md-12 form-group mb-3">
                        <label class="text-lg text-bold" for="lastName">Enter bicarbonate conc in meq/l<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" name="m1" id="m1" placeholder="bicarbonate conc in meq/l" ngModel #m1="ngModel">
                    </div>
                    <div class="col-md-12 form-group mb-3">
                        <label for="lastName">Enter PaCO2 in torr: <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" name="m2" id="m2" placeholder="enter PaCO2 in torr: " ngModel #m2="ngModel">
                    </div>
                    <div class="col-md-12 form-group mb-3">
                        <label for="lastName">for every g/l decrease in albumin, adjust AG up by 2.5 meq/L<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="ag" name="ag" placeholder="for every g/l decrease in albumin, adjust AG up by 2.5 meq/L" ngModel #ag="ngModel">
                    </div>
                </div>
                <!-- <hr class="text-warning"> -->
               
                <div class="col-md-12 form-group mb-3 text-center">
                    <btn-loading btnClass="btn-warning" style="width: 500px; height: 400px;" [loading]="loading">Submit the form</btn-loading>
                </div>
            </form>
        </div>
    </div>
</div>

