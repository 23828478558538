import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Industry } from '../models/modeles';

@Injectable({
  providedIn: 'root'
})
export class EventParamService {

  insert_company_url = environment.apidomain+"env/add-company";
  get_All_company_url = environment.apidomain+"env/get-all-company";

  insert_industry_url = environment.apidomain+"env/add-industry";
  get_All_industry_url = environment.apidomain+"env/get-all-industry";

  insert_regtype_url = environment.apidomain+"env/add-registration-type";
  get_All_regtype_url = environment.apidomain+"env/get-all-registration-type";

  insert_quality_url = environment.apidomain+"env/add-quality-guess";
  get_All_quality_url = environment.apidomain+"env/get-all-quality-guess";

  update_traitement_url = environment.apidomain+"traitement/update-traitement";

  constructor(private http: HttpClient) { }

  insertCompany<Company>(company: Company){
    return this.http.post(this.insert_company_url, company)
  }

  getAllCompany():Observable<any>{
    return this.http.get(this.get_All_company_url)
  }

  insertIndustry<Industry>(industry: Industry){
    return this.http.post(this.insert_industry_url, industry)
  }

  getAllIndustry():Observable<any>{
    return this.http.get(this.get_All_industry_url)
  }

  insertRegistrationType<RegistrationType>(regtype: RegistrationType){
    return this.http.post(this.insert_regtype_url, regtype)
  }

  getAllRegistrationType():Observable<any>{
    return this.http.get(this.get_All_regtype_url)
  }

  insertQualityGuess<QualityGuess>(quality: QualityGuess){
    return this.http.post(this.insert_quality_url, quality)
  }

  getAllQualityGuess():Observable<any>{
    return this.http.get(this.get_All_quality_url)
  }


  getAllReception(idcompany):Observable<any>{
    return this.http.get(this.get_All_company_url,{
      params:{
        "idcompany":idcompany
      }
    })
  }
}
