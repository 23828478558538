import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { RegisterComponent } from './views/manage-event/register/register.component';
import { ClinicalAnalysisComponent } from './views/manage-event/clinical-analysis/clinical-analysis.component';
import { DisplayRegistrationComponent } from './views/manage-event/display-registration/display-registration.component';
import { AjouterEntreeComponent } from './views/reception/ajouter-entree/ajouter-entree.component';
import { FileAttenteComponent } from './views/reception/file-attente/file-attente.component';
import { RecucaisseComponent } from './views/gestion-caisse/recucaisse/recucaisse.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'parametres',
      loadChildren: () => import('./views/departement/departement.module').then(m => m.DepartementModule)
    },
    {
      path: 'analytics',
      loadChildren: () => import('./views/analytics/analytics.module').then(m => m.AnalyticsModule)
    },
    {
      path: 'agents',
      loadChildren: () => import('./views/gestion-agent/gestion-agent.module').then(m => m.GestionAgentModule)
    },
    {
      path: 'reception',
      loadChildren: () => import('./views/reception/reception.module').then(m => m.ReceptionModule)
    },
    {
      path: 'caisse',
      loadChildren: () => import('./views/gestion-caisse/gestion-caisse.module').then(m => m.GestionCaisseModule)
    },
    {
      path: 'clients',
      loadChildren: () => import('./views/gestion-clients/gestion-clients.module').then(m => m.GestionClientsModule)
    },
    {
      path: 'projets',
      loadChildren: () => import('./views/gestion-projets/gestion-projets.module').then(m => m.GestionProjetsModule)
    },
    {
      path: 'traitement',
      loadChildren: () => import('./views/traitement/traitement.module').then(m => m.TraitementModule)
    },
    {
      path: 'produits',
      loadChildren: () => import('./views/gestion-produits/gestion-produits.module').then(m => m.GestionProduitsModule)
    },
    {
      path: 'uikits',
      loadChildren: () => import('./views/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
    },
    {
      path: 'forms',
      loadChildren: () => import('./views/forms/forms.module').then(m => m.AppFormsModule)
    },
    {
      path: 'invoice',
      loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
      path: 'inbox',
      loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule)
    },
    {
      path: 'calendar',
      loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarAppModule)
    },
    {
      path: 'chat',
      loadChildren: () => import('./views/chat/chat.module').then(m => m.ChatModule)
    },
    {
      path: 'contacts',
      loadChildren: () => import('./views/contacts/contacts.module').then(m => m.ContactsModule)
    },
    {
      path: 'tables',
      loadChildren: () => import('./views/data-tables/data-tables.module').then(m => m.DataTablesModule)
    },
    {
      path: 'pages',
      loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'manage-event',
        loadChildren: () => import('./views/manage-event/manage-event.module').then(m => m.ManageEventModule)
    },
    {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
    }
  ];

const routes: Routes = [
  {
    path:'clinic',
    component: ClinicalAnalysisComponent,
    /*children: [
      {
        path: 'reception',
        loadChildren: () => import('./views/reception/reception.module').then(m => m.ReceptionModule)
      }
    ]*/
  },
  {
    path:"ajouter-entree",
    component:AjouterEntreeComponent
  },
  {
    path:"file-attente",
    component:FileAttenteComponent
  },
  {
    path: 'manage',
    component:RegisterComponent,
    children: [
      {
        path: 'manage-event',
        loadChildren: () => import('./views/manage-event/manage-event.module').then(m => m.ManageEventModule)
      }
    ]
  },
  {
    path:'display/:id',
    component: DisplayRegistrationComponent
  },
  {
    path:"recu-caisse/:id",
    component:RecucaisseComponent
  },
  {
    path: '',
    redirectTo: 'admin/sessions/signin',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: 'admin',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: 'admin',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
