import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { User } from 'src/app/models/modeles';
import { UserService } from 'src/app/Services/user.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

    notifications: any[];
    errorMessage;
    loading: boolean;
    currentUser:User;
    isLoaded = false;
    nom="";
    nom_abrege="";
    service="";
    poste="";

    constructor(
      private navService: NavigationService,
      public searchService: SearchService,
      private auth: AuthService,
      private user_service:UserService,
      private auth_service:AuthenticationService,
      private router:Router,
      private toastr: ToastrService,
    ) {
      this.notifications = [
        {
          icon: 'i-Speach-Bubble-6',
          title: 'New message',
          badge: '3',
          text: 'James: Hey! are you busy?',
          time: new Date(),
          status: 'primary',
          link: '/chat'
        },
        {
          icon: 'i-Receipt-3',
          title: 'New order received',
          badge: '$4036',
          text: '1 Headphone, 3 iPhone x',
          time: new Date('11/11/2018'),
          status: 'success',
          link: '/tables/full'
        },
        {
          icon: 'i-Empty-Box',
          title: 'Product out of stock',
          text: 'Headphone E67, R98, XL90, Q77',
          time: new Date('11/10/2018'),
          status: 'danger',
          link: '/tables/list'
        },
        {
          icon: 'i-Data-Power',
          title: 'Server up!',
          text: 'Server rebooted successfully',
          time: new Date('11/08/2018'),
          status: 'success',
          link: '/dashboard/v2'
        },
        {
          icon: 'i-Data-Block',
          title: 'Server down!',
          badge: 'Resolved',
          text: 'Region 1: Server crashed!',
          time: new Date('11/06/2018'),
          status: 'danger',
          link: '/dashboard/v3'
        }
      ];

    }
  
    ngOnInit() {
      this.getCurrentUser();
    }
  
    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      // item has child items
      if (!state.sidenavOpen && !state.childnavOpen 
        && this.navService.selectedItem.type === 'dropDown') {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
      // item has no child items
      if (!state.sidenavOpen && !state.childnavOpen) {
        state.sidenavOpen = true;
      }
    }
  
    signout() {
      //localStorage.setItem('token' , null);
      this.currentUser = null;
      localStorage.removeItem('currentUser');
      //this.auth.signout();
      this.router.navigateByUrl('/admin/sessions/signout');
    }

    /*getCurrentUser(){
      this.user_service.currentUser.subscribe(data=>{
          this.isLoaded = true
          this.currentUser = data;
          this.nom = this.currentUser !== null? this.currentUser.agent.nom+" "+this.currentUser.agent.prenom : "";
          this.nom_abrege = this.currentUser !== null? this.currentUser.agent.nom[0]+""+this.currentUser.agent.prenom[0] : "";
          this.service = this.currentUser.agent.service === null ? "":this.currentUser.agent.service.libelle
      });
    }*/

    getCurrentUser(){
      var token;
      this.user_service.currentUser.subscribe(data=>{
        
        if(data.token == undefined){
          token = localStorage.getItem('token');
          this.auth_service.loginWToken(token).subscribe({
            next:data =>{
              if(data != null){
                this.currentUser = data;
                this.isLoaded = true
                this.currentUser = data;
                this.nom = this.currentUser !== null ? this.currentUser.agent.nom + " " + this.currentUser.agent.prenom : "";
                this.nom_abrege = this.currentUser !== null ? this.currentUser.agent.nom[0] + "" + this.currentUser.agent.prenom[0] : "";
                this.service = this.currentUser.agent.service === null ? "Administrateur":this.currentUser.agent.service.libelle;
                this.poste = this.currentUser.agent.role === null ? "Administrateur": this.currentUser.agent.role.role_name;

              }else{
                this.router.navigateByUrl('/admin/sessions/signin');
              }
            },
            error:error =>{
                this.errorMessage = error.message;
                console.error("There is an error !", error)
                setTimeout(() => {
                  this.loading = false;
                  this.toastr.error('There is an error.', 'Error!', {progressBar: true});
                }, 3000);
            }
          })
        }
        else{
          this.currentUser = data;
          this.isLoaded = true
          this.currentUser = data;
          this.nom = this.currentUser !== null? this.currentUser.agent.nom+" "+this.currentUser.agent.prenom : "";
          this.nom_abrege = this.currentUser !== null? this.currentUser.agent.nom[0]+""+this.currentUser.agent.prenom[0] : "";
          this.service = this.currentUser.agent.service === null ? "Administrateur":this.currentUser.agent.service.libelle;
          this.poste = this.currentUser.agent.role === null ? "Administrateur": this.currentUser.agent.role.role_name;
          //console.log("the connected user is "+JSON.stringify(data));
        }
      });
    }
}
